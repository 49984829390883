// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// const productionFlag = false;
const developmentFlag = false;

// const baseHref = `http://localhost:4200`;
// const baseHref = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;

const hostName = window.location.hostname;
let newAPIURL = 'https://devapi.decorait.com';
let newStyleURL = 'https://customer-api.decorait.com'

if(hostName.includes('moodmaestro')){
  newAPIURL = 'https://api.decorait.com'
  newStyleURL = 'https://customer-prodapi.decorait.com'
}
if(hostName.includes('decorait.store')){
  newAPIURL = 'https://api.decorait.com'
}
else if(hostName.includes('decoraitrade')){
  newAPIURL = 'https://devapi.decorait.com';
}

export const environment = {
  // apiBaseUrl: developmentFlag ? 'http://localhost:3267' :'https://test-api.moodmaestro.co.uk',
  apiBaseUrl: 'https://api.moodmaestro.co.uk',

  apiNewUrl: newAPIURL,
//   apiNewUrl: 'http://localhost:8080',

  styleBaseUrl:newStyleURL,
//   styleBaseUrl: 'http://localhost:8081',

  newBaseUrl: 'http://moodwiz-env.eba-wqzghzmp.us-east-2.elasticbeanstalk.com',
  production: false,
  facebookAppID: '175627623079742',
  development: developmentFlag,
  postCodeApiUrl: 'http://api.postcodes.io/postcodes/',
  googleMapsKey: 'AIzaSyD_KrhmjPP4cIkFhrw6FX3OyLeP1ocObpw',
  throttleTime: 500,
  showErrorTimeout: 5000,
  defaultPersonroomTemplate: 'default',
  defaultCacheExpiryMinutes: 60 * 1,
  baseHref: developmentFlag
    ? 'http://localhost:4200'
    : 'https://www.moodmaestro.co.uk',

  cognito: {
    ClientId: '7ub9g0uluq41u8qf1i9v59vl12',
    // AppWebDomain: 'iosmoodmaestro.auth.eu-west-1.amazoncognito.com',
    AppWebDomain: 'auth.moodmaestro.co.uk',

    TokenScopesArray: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    UserPoolId: 'eu-west-1_pCaObeQZr',
    AdvancedSecurityDataCollectionFlag: true,
    responseType: 'code',
    RedirectUriSignIn: '$baseHref/post-login',
    RedirectUriSignOut: '$baseHref',
  },

  stripe: {
    publishableKey: 'pk_test_51NdxzvIZ56kf0iKLDu1A9CeNasmRnR1d9qU3P8AKjAMmVD61sDokeLDyMKzUgZhXhENgOUmops2Z5nD7RrFE3FJw00lZW2RKEt',
    /* publishableKey: 'pk_live_SFJVdPJNWfmAa7r8UOSco9Ab', */
  },
  chatBot: {
    botName: 'HeadlineBot',
    botAlias: 'Test',
  },
  incomingMail: {
    sellerEMailTemplate: `test-seller+{shipmentID}@mail.moodmaestro.co.uk`,
  },
  zendesk: {
    url: 'moodmaestro.zendesk.com',
  },
};

