// import 'rxjs/add/observable/of';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { HttpService } from './http.service';
import {
  ProductListResponse,
  VariantListResponse,
  ProductEvent,
  Product,
} from '../classes/product';
import { InventoryListResponse } from '../classes/inventory';
import { SellerListResponse } from '../classes/seller';
import { AuthService } from './auth.service';
import { LocalStorage } from './utils.service';
import { Verdict } from '../classes/game';
import { GTMProduct, GTMEcommerceData } from '../classes/ecommerce';
import { TagService } from './tag.service';
import { isPlatformBrowser } from '@angular/common';
import { first } from 'rxjs/operators';
import { Tag } from '../classes/tag';
import { NewHttpService } from './new.http.service.service';
// import { ErrorObservable } from 'rxjs/observable/ErrorObservable';

@Injectable()
export class ProductService {
  isBrowser: boolean;
  brandTags: Map<string, Tag>;
  categoryTags: Map<string, Tag>;

  // It's a JSON which is used to get products
  filters = new Subject();
  // For update sidebar automatically when tags changes
  tags = [];
  updatedTags = new Subject();

  // To display chips for selected filters
  selectedTagNameList = new Subject();
  selectedTagNames = [];
  parentNames: any = {};
  selectedFilters = {
    brand: {},
    category: {},
    room: {},
    colour: {},
    material: {},
    filter: {},
    style: {}
  };

  constructor(
    private http: HttpService,
    private htttp: NewHttpService,
    private auth: AuthService,
    private localStorage: LocalStorage,
    private tagService: TagService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      this.tagService
        .cachedTagsList('brand')
        .pipe(first())
        .subscribe((result) => {
          this.brandTags = result;
        });

      this.tagService
        .cachedTagsList('category')
        .pipe(first())
        .subscribe((result) => {
          this.categoryTags = result;
        });
    }
  }

  getProductsList(
    searchJson: any,
    limit: number,
    skip: number,
    sort: string
  ): Observable<ProductListResponse> {
    let url = '/v1/api/products/postproductslist?';
    if (limit) {
      url += `limit=${limit}&`;
    }
    if (skip) {
      url += `skip=${skip}&`;
    }
    if (sort) {
      url += `sort=${sort.replace(' ', '+')}&`;
    }
    return this.http.postHttpJson(url, searchJson);
  }
  elasticProductsList(
    searchJson: any,
    prefix: string,
    limit: number,
    skip: number,
    sort: string,
    seed: string
  ): Observable<ProductListResponse> {
    let url = '/v1/api/products/postelasticsearchproducts?';
    if (limit) {
      url += `limit=${limit}&`;
    }
    if (skip) {
      url += `skip=${skip}&`;
    }
    if (sort) {
      url += `sort=${sort.replace(' ', '+')}&`;
    }
    if (prefix) {
      url += `prefix=${prefix.replace(' ', '+')}&`;
    }
    if (seed) {
      url += `seed=${seed}&`;
    }
    return this.http.postHttpJson(url, searchJson);
  }
  elasticPersonalisedStore(
    // new
    verdict: Verdict,
    searchJson: any,
    prefix: string,
    limit: number,
    skip: number,
    sort: string,
    seed: string
  ): Observable<ProductListResponse> {
    let url = '/v1/api/products/postpersonalisedstore?';
    if (limit) {
      url += `limit=${limit}&`;
    }
    if (skip) {
      url += `skip=${skip}&`;
    }
    if (sort) {
      url += `sort=${sort.replace(' ', '+')}&`;
    }
    if (prefix) {
      url += `prefix=${prefix.replace(' ', '+')}&`;
    }
    // if (verdictId) { url += `verdictID=${verdictId}&`; }
    if (seed) {
      url += `seed=${seed}&`;
    }
    return this.http.postHttpJson(url, {
      verdict: verdict.verdict,
      search: searchJson,
    });
  }

  elasticPersonalisedProductsList(
    verdictId: string,
    searchJson: any,
    prefix: string,
    limit: number,
    skip: number,
    sort: string,
    seed: string
  ): Observable<ProductListResponse> {
    if (!this.auth.isLoggedIn()) {
      return throwError({ error: { message: 'You must be logged in' } });
    }

    let url = '/v1/api/products/postpersonalisedsearchproducts?';
    if (limit) {
      url += `limit=${limit}&`;
    }
    if (skip) {
      url += `skip=${skip}&`;
    }
    if (sort) {
      url += `sort=${sort.replace(' ', '+')}&`;
    }
    if (prefix) {
      url += `prefix=${prefix.replace(' ', '+')}&`;
    }
    if (verdictId) {
      url += `verdictID=${verdictId}&`;
    }
    if (seed) {
      url += `seed=${seed}&`;
    }
    return this.http.postHttpJson(url, searchJson);
  }

  getVariantsList(variantIdArray: string[]): Observable<VariantListResponse> {
    const url = '/v1/api/products/postvariantslist?';
    return this.http.postHttpJson(url, variantIdArray);
  }
  getVariantsByProductList(
    prodIdArray: string[]
  ): Observable<VariantListResponse> {
    const url = '/v1/api/products/postvariantsbyproductlist?';
    return this.http.postHttpJson(url, prodIdArray);
  }

  // getProductsDetails(id: string): Observable<ProductResponse> {
  //     return this.http.getHttpJson('/v1/api/products/getproductdetails?productID=' + id);
  // }

  // pankaj
  getProductsDetails(id: string): Observable<Product> {
    return this.htttp.get('web/product/v/' + id);
  }

  getInventoryList(
    searchJson,
    limit,
    skip,
    sort
  ): Observable<InventoryListResponse> {
    let url = '/v1/api/inventory/postinventorylist?';
    if (limit) {
      url += `limit=${limit}&`;
    }
    if (skip) {
      url += `skip=${skip}&`;
    }
    if (sort) {
      url += `sort=${sort}&`;
    }
    return this.http.postHttpJson(url, searchJson);
  }

  clearSearchParameters() {
    this.localStorage.setItem('products/search', null);
  }
  saveProductSearch(obj: any) {
    this.localStorage.setItem('products/search', JSON.stringify(obj));
  }
  updateProductSearch(propertyKey: string, prop: any, clearOthers: boolean) {
    const searchStr = this.localStorage.getItem('products/search');
    if (searchStr) {
      try {
        let obj = JSON.parse(searchStr);
        if (clearOthers) {
          obj = {};
        }
        obj[propertyKey] = prop;
        this.localStorage.setItem('products/search', JSON.stringify(obj));
      } catch (e) { }
    }
  }

  restoreProductSearch(): any {
    const searchStr = this.localStorage.getItem('products/search');
    if (searchStr) {
      try {
        const obj = JSON.parse(searchStr);
        return obj;
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  }

  getRecommmedations(
    list: ProductEvent[],
    limit: number,
    skip: number,
    sort: string
  ): Observable<ProductListResponse> {
    let url = '/v1/api/products/postrecommendations?';
    if (limit) {
      url += `limit=${limit}&`;
    }
    if (skip) {
      url += `skip=${skip}&`;
    }
    if (sort) {
      url += `sort=${sort.replace(' ', '+')}&`;
    }
    return this.http.postHttpJson(url, list);
  }
  sellersList(
    sellerIdArray: string[],
    limit: number,
    skip: number,
    sort: string
  ): Observable<SellerListResponse> {
    let url = '/v1/api/sellers/postsellerslist?';
    if (limit) {
      url += `limit=${limit}&`;
    }
    if (skip) {
      url += `skip=${skip}&`;
    }
    if (sort) {
      url += `sort=${sort.replace(' ', '+')}&`;
    }
    return this.http.postHttpJson(url, { sellerID: { $in: sellerIdArray } });
  }

  getSellerStore(
    searchJson: any,
    sellerId: string,
    limit: number,
    skip: number,
    sort: string
  ): Observable<ProductListResponse> {
    let url = `/v1/api/products/postsellerstore?sellerID=${sellerId}&`;
    if (limit) {
      url += `limit=${limit}&`;
    }
    if (skip) {
      url += `skip=${skip}&`;
    }
    if (sort) {
      url += `sort=${sort.replace(' ', '+')}&`;
    }
    return this.http.postHttpJson(url, searchJson);
  }

  getGTMProduct(product: Product, options: GTMProduct): GTMProduct {
    const dataLayerProduct = <GTMProduct>{
      name: product.name, // Name or ID is required.
      id: product._id,
      brand:
        this.brandTags && this.brandTags[product.brand]
          ? this.brandTags[product.brand].name
          : '',
      // brand: this.brandTags[product.brand] ? this.brandTags[product.brand].name : '',
      category: Array.isArray(product.category)
        ? product.category
          .sort((a, b) => (a.length > b.length ? 1 : -1))
          .map((x) =>
            this.categoryTags && this.categoryTags[x]
              ? this.categoryTags[x].name
              : ''
          )
          .join('/')
        : '',
      // variant: product.defaultVariant,
    };
    if (product.actualPrice) {
      dataLayerProduct.price = product.actualPrice.toString();
    }

    return Object.assign(dataLayerProduct, options);
  }
  getNewDataLayer(): GTMEcommerceData {
    return <GTMEcommerceData>{
      ecommerce: {
        currencyCode: undefined,
        impressions: undefined,
        add: undefined,
        remove: undefined,
        promoView: undefined,
        promoClick: undefined,
        detail: undefined,
        click: undefined,
        checkout: undefined,
        purchase: undefined,
        refund: undefined,
      },
    };
  }

  getBrandWiseProducts() {
    return this.http.getHttpNewJson('web/brand-products');
  }

  // Get all products
  getProducts(page?: any, size?: any) {
    return this.http.getHttpNewJson(`web/products?page=${page}`);
  }

  // getProductByCategory(page: any, type: any) {
  //   return this.http.postHttpNewJson(`web/products?page=${page}`, {
  //     'filter': type
  //   });
  // }

  // Not In used
  // filtered products
  getProductByCategory(page: any, filter: any) {
    return this.http.postHttpNewJson(`web/products?page=${page}`, {
      filter: filter
    });
  }

  // Filters
  getFilteredProductList(page: any, val: any) {
    return this.http.postHttpNewJson(`web/1/filter/products?page=${page}`, val);
  }

  // Get products using search string
  getProductByusingSearchString(page: any, searchString: string) {
    return this.http.postHttpNewJson(`web/products?page=${page}`, { query: searchString });
  }

  // Used when need to fetch product using only single tag
  // EX: From store menu, click to tag and redirect to product list page
  // EX: Same for product detail page
  getProductsByTagId(page: any, tagId: string) {
    return this.http.getHttpNewJson(`web/products?page=${page}&tagId=${tagId}`);
  }

  getRecommendedProducts() {
    return this.http.getHttpNewJson(`web/1/products/recommended`);
  }

  setFilters(val: any, type?: string) {
    const f = {
      val: val,
      type: type
    };
    this.filters.next(f);
  }

  clearFilters() {
    const selectedTags = {
      brand: {},
      category: {},
      room: {},
      colour: {},
      material: {},
      filter: {},
      style: {}
    };
    this.setSelectedFilters(selectedTags);
    this.setTagNameListForSelectedFilters([]);
    this.setFilters(selectedTags);

  }

  setSelectedFilters(val: any) {
    this.selectedFilters = val;
  }

  getSelectedFilters(): any {
    return this.selectedFilters;
  }

  setTags(tags: any) {
    this.tags = tags;
    this.updatedTags.next({ tags: tags, parentNames: this.parentNames });
  }
  setParentNames(parentNames: any) {
    this.parentNames = parentNames;
  }
  getParentNames() {
    return this.parentNames;
  }
  getTags() {
    return this.tags;
  }

  getTagNameListForSelectedFilters() {
    return this.selectedTagNames;
  }

  setTagNameListForSelectedFilters(tagNameList: Array<any>) {
    this.selectedTagNames = tagNameList;
    this.selectedTagNameList.next(this.selectedTagNames);
  }

  getPluralNameOfCategoryFilter(singularName: string) {
    let pluralName = null;
    switch (singularName) {
      case 'brand':
        pluralName = 'brandsUrlSlug';
        break;

      case 'category':
        pluralName = 'categoriesUrlSlug';
        break;

      case 'filter':
        pluralName = 'moodclipsUrlSlug';
        break;

      case 'color':
      case 'colour':
        pluralName = 'colorsUrlSlug';
        break;

      case 'material':
        pluralName = 'materialsUrlSlug';
        break;

      case 'room':
        pluralName = 'roomsUrlSlug';
        break;

      case 'style':
        pluralName = 'stylesUrlSlug';
        break;

      default:
        break;
    }
    return pluralName;
  }

  // web/tag/tagId
  findCategoryInfoByTagId(tagId: string) {
    if (tagId && this.tags) {
      let arr = [];
      Object.keys(this.tags).forEach((key: any) => {
        arr = arr.concat(this.tags[key]);
      });
      return arr.find(val => val.tagId == tagId);
    }
  }

  saveBookmark(productId: string) {
    return this.http.getHttpJson(`/v1/api/bookmarks/getadditemtobookmark?itemID=${productId}&itemType=products&now=${String(new Date())}`);
  }

  getBookmarks() {
    return this.http.postHttpJson(`/v1/api/bookmarks/postbookmarkdetails`, { itemType: 'products' });
  }

  removeBookmark(productIds) {
    return this.http.postHttpJson(`/v1/api/bookmarks/postremoveitemfrombookmark?itemType=products`, {
      itemID: productIds
    });
  }

  getSimilarProducts(productId) {
    return this.http.getHttpNewJson(`web/1/product/${productId}/similar`);
  }

  getAssociatedProducts(productId) {
    return this.http.getHttpNewJson(`web/1/product/${productId}/associated`);
  }

  // getTagsByCategoryId(tagId) {
  //   return this.http.getHttpNewJson(`web/product/${productId}/associated`);
  // }
  getPriceByEmail(payload:any){
    return this.http.postCustomerJsonBaseUrl(`api/customer/product-enquiry`, payload)
  }

  getPriceRequests(page:number, size:number){
    return this.http.getCustomerJsonBaseUrl(`api/customer/product-enquiry?page=${page}&size=${size}`)
  }

  acceptRejectProductEnquiry(productEnquiryId:string, offerPriceId:string, status:boolean, message:string){
    return this.http.putCustomerJsonBaseUrl(`api/customer/product-enquiry/${productEnquiryId}/offer-price/${offerPriceId}?approved=${status}`, {
      approved:status,
      reason:message
    });
  }
}
